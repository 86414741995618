<template lang="html">
  <div class="sm:container py-10 md:py-24 home-inmob">
  
    <!-- CARRUSEL ENTRARA COMO MEJOR

      <div class="row seccion-home-carrusel mt-12 mb-6 flex flex-row justify-center">
        <p class="fnt-size-graf mb-4">Cambios y actualizaciones</p>
        <div id="carrusel" class="col-sm-12">
            <a href="#" class="left-arrow"><img class="w-6" src="../../../assets/iconos/izq.svg"/></a>
            <a href="#" class="right-arrow"><img class="w-6" src="../../../assets/iconos/der.svg"/></a>        
            <div class="carrusel">
              <div id="product_0" class="col-sm-3 bg-dato-graf alineado-centro-item mr-4 ml-4 h-32">
                <a href="#">
                  <span>SOC RACING 2.0 </span>
                </a>
              </div>
              <div id="product_1" class="col-sm-3 bg-dato-graf alineado-centro-item mr-4 ml-4 h-32">
                <a href="#">
                  <span> Plataforma Inmuebles</span>
                </a>
              </div>
              <div id="product_2" class="col-sm-3 bg-dato-graf alineado-centro-item mr-4 ml-4 h-32">
                <a href="#">
                  <span> Próximos webinars </span>
                </a>
              </div>
              <div id="product_3" class="col-sm-3 bg-dato-graf alineado-centro-item mr-4 ml-4 h-32">
                <a href="#">
                  <span>Ejemplo </span>
                </a>
              </div>   
              <div id="product_4" class="col-sm-3 bg-dato-graf alineado-centro-item mr-4 ml-4 h-32">
                <a href="#">
                  <span>Ejemplo2 </span>
                </a>
              </div>   
              <div id="product_5" class="col-sm-3 bg-dato-graf alineado-centro-item mr-4 ml-4 h-32">
                <a href="#">
                  <span>Ejemplo3</span>
                </a>
              </div>            
            </div>

        </div>
      </div>
    -->
    <!--Aqui Termina el carrusel-->
      
      <div class="row bg-white home-inmob-main">
          <!--Aqui empieza la columna izquierd  a contenedor de grafica-->
          <div class="col-sm-12 col-md-6 pdng-0" id="graficaCSS">
            <div class="seccion-home-inmo grafica-contenedor mb-4" v-if="banderaGrafica">
                <!--<Grafica :data="chartData" :labels="chartLabels" />-->
                <Grafica2 :clientesNuevos="casosPrecalificacion" :SimulacionesEquipo="casosMyOrganizacion" :misSimulaciones="misCasos" />                
            </div>
          </div>
          <!--Aqui termina la columna izquierda contenedor de grafica-->

          <!--Aqui empieza la columna derecha contenedor de datos-->
          <div class="col-sm-12 col-md-6">
          <!--Aqui empieza la columna derecha contenedor de datos primera seccion-->
              <div class="row seccion-home-inmo mb-4">
                  <div class="row mt-4 mb-4">
                      <div class="col-sm-9">
                          <p class="fnt-size-graf">Total de simulaciones</p>
                      </div>
                      <div class="col-sm-3 alng-center bg-dato-graf">
                          <span class="fnt-size-graf">{{totalCasos}}</span>
                      </div>
                  </div>
              </div>
          <!--Aqui empieza la columna derecha contenedor de datos segunda seccion-->
              <div class="row seccion-home-inmo">
                  <div class="row mt-4">
                      <div class="col-sm-9">
                        <p class="fnt-size-graf">Clientes nuevos</p>
                      </div>
                      <div class="col-sm-3 alng-center bg-dato-graf">
                         <span class="fnt-size-graf">{{clientesNuevos}}</span>
                      </div>
                  </div>
                  <div class="row mt-4">
                      <div class="col-sm-9">
                         <p class="fnt-size-graf">Mis simulaciones</p> 
                      </div>
                      <div class="col-sm-3 alng-center bg-dato-graf">
                          <span class="fnt-size-graf">{{misCasos}}</span>
                      </div>
                  </div>
                  <div class="row mt-4">
                      <div class="col-sm-9">
                          <p class="fnt-size-graf">Oficina</p>
                      </div>
                      <div class="col-sm-3 alng-center bg-dato-graf">
                          <span class="fnt-size-graf">{{casosMyOrganizacion}}</span> {{ casosBroker.Broker }}
                      </div>
                  </div>
          <!--Aqui Termina la columna derecha contenedor de datos segunda seccion-->

          <!--Aqui Comienza la columna derecha contenedor de datos tercera seccion-->
                  <div class="row mt-4" v-for="(item, name, index) in casosBroker" :key="index" v-if="name < 5 ">
                      <div class="col-sm-9 alng-rtg">
                          <p class="fnt-size-graf pr-4">{{ item.Broker }}</p>
                      </div>
                      <div class="col-sm-3 alng-center bg-dato-graf">
                          <span class="fnt-size-graf">{{item.Casos}}</span> 
                      </div>
                  </div>
          <!--Aqui termina la columna derecha contenedor de datos tercera seccion-->
                <div class="row mt-4 alng-center fnt-size-graf"> 
                  <!--<router-link to="/inmobiliaria" class="flex justify-center">
                    Ver mas
                  </router-link>-->
                  <a @click="handleSelected(5)"> Ver mas </a>
                </div>
          <!--Aqui termina la columna derecha contenedor de datos-->
              </div>
          </div>
      </div>

  </div>

</template>
    
<script>

import Grafica2 from '@/views/CasoInmobiliaria/nuevasVistas/grafica2.vue';

export default {
  components: {    
    Grafica2    

},
  data() {
    return {
      chartData: [25, 40, 30, ], // Aquí pon los datos para la gráfica
      chartLabels: ['Preca', 'Mis ejercicios', 'Equipo' ], // Aquí pon las etiquetas para la gráfica
      casosBroker: [],
      allcasos: [],
      misCasos: '',
      key: '',
      totalCasos: '',
      clientesNuevos: '',
      broker: '',
      casosMyOrganizacion: '',
      casosPrecalificacion: '',
      banderaGrafica : false
    }
  },
  mounted(){
    if(!JSON.parse(localStorage.getItem('userInfo'))){
        this.$router.push('/login').catch(() => {})
    }else {
        this.getApikey()        
        this.getAllCasos()  
        this.getCasosPrecalificacion()      
    }
    
    this.$vs.loading({            
      scale: 0.6
    })           
    this.activaPanel();
  },
  methods:{
    handleSelected(tr) {
      this.$emit('actualiza_panel', 'HomeInmo', 'BaseClientes')
      this.$router.push('/inmobiliaria/'+tr).catch(() => {})      
    },    
    activaPanel(){
      this.$emit('actualiza_panel', 'HomeInmo')
    },
    getApikey(){      
      this.key=JSON.parse(localStorage.getItem('userInfo')).ApiKey;
      this.broker = JSON.parse(localStorage.getItem('userInfo')).Broker;      
    },
    getAllCasos(){     
      let self = this
      var ObjRequestGetAllCasos = {
        strApiKey: this.key,
				strMetodo: 'ListaCasosJerarquiaInmobiliaria',
        objParametros: {
           Limite:900,                            // limite 900 para prod  // pruebas 200
           FechaInicio : "01-01-2023 00:00:00"    // Obtener casos a partir de año Actual >= 2023 
       }
      }
      this.$axios.post('/',ObjRequestGetAllCasos,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
				response => {
					if(response.data.intEstatus == 0){            
            this.allcasos=response.data.objContenido            
            this.totalCasos = this.allcasos.length            

            var arrayDuplicados = []  // solicitante duplicados
            var duplicadosBroker = []

						for (var i = 0; i < this.allcasos.length; i++) {
              arrayDuplicados.push(this.allcasos[i].Solicitante)
              duplicadosBroker.push(this.allcasos[i].Broker)
            }                        
            // solicitante unicos
            var uniqs = arrayDuplicados.filter(function(item, index, array) {
              return array.indexOf(item) === index;
            })            
            this.clientesNuevos = uniqs.length                  
            
            this.getSolicitudes(this.key)

            var uniqsBrokers = duplicadosBroker.filter(function(item, index, array) {
              return array.indexOf(item) === index;
            })                                         
            
            for (let index = 0; index < uniqsBrokers.length; index++) {              
              this.getApikeyBroker(uniqsBrokers[index])

            }                                   
					}else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaCasos',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
          this.$vs.loading.close('#casos > .con-vs-loading')
				}			
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
			}) 
    },
    getSolicitudes(key, Broker){
      let self=this
			var objRequestListaCasos = {
				strApiKey: key,
				strMetodo: 'ListaCasos2',
        objParametros: {
          FechaInicio : "01-01-2023 00:00:00"  // Obtener casos a partir de año Actual >= 2023 
        }
			}
			this.$axios.post('/',objRequestListaCasos,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
			.then(
				response => {
					if(response.data.intEstatus == 0){
            if (!Broker) {
              this.misCasos= response.data.objContenido.length              
              this.casosMyOrganizacion = this.totalCasos - this.misCasos     
              this.banderaGrafica = true  
              
              this.$vs.loading.close()

            }else{
              let res = response.data.objContenido.length              
              
              this.casosBroker.push({Broker: Broker, Casos: res})          
              this.casosBroker.sort((x, y) => y.Casos - x.Casos);                   
            }            
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaCasos',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }         
				}
			).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
			})
    },
    getApikeyBroker(broker){
      let self=this
			var objRequestListaCasos = {
				strApiKey: this.key,
				strMetodo: 'DameBroker',
        objBroker : {
          EMail: broker
        }
			}
			this.$axios.post('/',objRequestListaCasos,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
			.then(
				response => {
					if(response.data.intEstatus == 0){
            let apiKey = response.data.objContenido.ApiKey            
            this.getSolicitudes(apiKey, response.data.objContenido.Nombre)            
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaCasos',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }          
				}
			).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
			})
    },
    getCasosPrecalificacion(){
      let objRequestGetCasosPrecalifica = {
        strApiKey: this.key,        
				strMetodo: 'ListaCasosPorFiltro',
        objFiltro:{
          filtroCasos: 7 // filtro de precalificacion online - Solo usuarios de tipo inmobiliario
        }			
      }
      this.$axios.post('/', objRequestGetCasosPrecalifica, {headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
					if(response.data.intEstatus == 0){
            this.casosPrecalificacion = response.data.objContenido.length            
					}else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaCasos',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
				}
			).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
			})
		},
  },
}
var current = 0;
var imagenes = new Array();
 
$(document).ready(function() {
    var numImages = 6;
    if (numImages <= 3) {
        $('.right-arrow').css('display', 'none');
        $('.left-arrow').css('display', 'none');
    }
 
    $('.left-arrow').on('click',function() {
        if (current > 0) {
            current = current - 1;
        } else {
            current = numImages - 3;
        }
 
        $(".carrusel").animate({"left": -($('#product_'+current).position().left)}, 600);
 
        return false;
    });
 
    $('.left-arrow').on('hover', function() {
        $(this).css('opacity','0.5');
    }, function() {
        $(this).css('opacity','1');
    });
 
    $('.right-arrow').on('hover', function() {
        $(this).css('opacity','0.5');
    }, function() {
        $(this).css('opacity','1');
    });
 
    $('.right-arrow').on('click', function() {
        if (numImages > current + 3) {
            current = current+1;
        } else {
            current = 0;
        }
 
        $(".carrusel").animate({"left": -($('#product_'+current).position().left)}, 600);
 
        return false;
    }); 
 });
  
</script>

<style lang="scss" scoped>

.alineado-centro{
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
}
.pdng-0{
  padding: 0px;
}
.grafica-contenedor{
  padding: 0px;
  margin-right: 1rem;
}
.alineado-centro-item{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.seccion-home-inmo{
    background-color:#F2F2F2;
    border-radius: 7px;
    padding: 20px;

    @media screen and (min-width: 768px) {
      padding: 30px 16px;
    }

    @media screen and (min-width: 992px) {
      padding: 30px;
    }
}
.seccion-home-carrusel{
    background-color:#F2F2F2;
    border-radius: 7px;
    padding: 10px;
}
.alng-rtg{
  text-align: right;
}
.alng-center{
  text-align: center;
}
.fnt-size-graf{
  font-size: 1rem;
  margin-bottom: 0;
}
.bg-dato-graf{
  background-color: #fff;
  border-radius: 10px;
}
.text-toggle-componente{
  color:#006D4E;
}
@media (max-width: 769px) {
  .grafica-contenedor{
    margin-right:0px ;
  }
}

#carrusel {
    float:left;
    width:840px;
    overflow:hidden;
    height:115px;
    position:relative;
   /* margin-top:20px;
    margin-bottom:20px;*/
}
 
#carrusel .left-arrow {
    position:absolute;
    left:10px;
    z-index:1;
    top:50%;
    margin-top:-9px;
}
 
#carrusel .right-arrow {
    position:absolute;
    right:10px;
    z-index:1;
    top:50%;
    margin-top:-9px;
}
 
.carrusel {
    width:4000px;
    left:0px;
    position:absolute;
    z-index:0;
}
 
.carrusel>div {
    float: left;
    height: 203px;
    margin-right: 5px;
    /*width: 195px;*/
    width: 251px;
    text-align:center;
}
 
.carrusel img {
    cursor:pointer;
}
 
.product {
    border:#CCCCCC 1px solid;
}

/****** Nuevos estilos  ******/
.home-inmob-chart {
  width: 100%;

  @media screen and (max-width: 575px) {
    min-height: 25em;
  }
}

.home-inmob {
  &-main {
    @media screen and (max-width: 767px) {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

</style>