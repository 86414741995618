<template lang="html">
<div>
  <Inmobiliaria v-if="inmobiliaria"></Inmobiliaria>
  <!-- <HomeInmo v-if="inmobiliaria"></HomeInmo> -->
  <Soc :guid=guid v-if="soc"></Soc>
</div>
</template>
<script>
import Inmobiliaria from '@/views/Inmobiliaria.vue'
import HomeInmo from '@/views/CasoInmobiliaria/nuevasVistas/Home.vue'
import Soc from '@/views/Soc.vue'
export default {
  props: {
    guid: {
      type: String,
      required: false
    },
  },
  components: {
    Inmobiliaria,
    HomeInmo,
    Soc
  },
  data(){
		return{
      soc:false,
      inmobiliaria:false
		}
  },
  created() {
    this.guid && this.$router.push(`/loading/?guid=${ this.guid }`).catch(() => {})
  },
  mounted() {
    const tipoEjecutivoV3 = this.$store.state.AppActiveUser.TipoEjecutivoV3

    if ( tipoEjecutivoV3 === 'Inmo' ) return this.$router.push("/homeInmo").catch(() => {})

    this.soc = true
  }
}
</script>
